import React from 'react';
import { motion } from 'framer-motion';

function About() {
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  return (
    <div className="min-h-screen bg-gray-00 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <motion.h1 
          className="text-4xl font-bold text-center text-gray-800 mb-12"
          {...fadeIn}
        >
          Experience
        </motion.h1>

        <motion.div 
          className="flex flex-col md:flex-row items-center md:items-start gap-8 mb-12"
          {...fadeIn}
        >
          <div className="w-full md:w-1/3 aspect-w-1 aspect-h-1 rounded-lg overflow-hidden shadow-md">
            <img 
              src="/experience/journey.jpg" 
              alt="My Journey" 
              className="object-cover w-full h-full"
            />
          </div>
          <div className="w-full md:w-2/3 bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-4 text-indigo-600">My Journey</h2>
            <p className="text-gray-700 mb-4">
            When I entered the University of Michigan, I planned to study biomedical engineering, due to my passion for orthopedics and entrepreneurship. 
            However, I soon discovered the versatility of mechanical engineering, which offered broader opportunities across industries. Joining the UofM 
            Solar Car Team confirmed my choice, as I saw the impactful and diverse projects mechanical engineers took on.
            </p>
            <p className="text-gray-700 mb-4">
            During my sophomore summer, I interned at Woodward Aerospace, and I realized I craved a faster, more innovative work environment. This led me to
            intern at Epirus, a defense startup, where I thrived in a high-speed, “fail forward” culture.
            </p>
            <p className="text-gray-700">
            As I approach graduation, I’m eager to pursue a career in an agile work environment, specifically as a manufacturing or systems engineer. 
            I am drawn to the hands-on, problem-solving work and the opportunity to contribute to a greater mission.
            </p>
          </div>
        </motion.div>

        <motion.div 
          className="flex flex-col md:flex-row items-center md:items-start gap-8 mb-12"
          {...fadeIn}
        >
          <div className="w-full md:w-2/3 bg-white p-6 rounded-lg shadow-md order-2 md:order-1">
            <h2 className="text-2xl font-semibold mb-4 text-indigo-600">Beyond Engineering</h2>
            <p className="text-gray-700 mb-4">
              While engineering is my passion, I believe in maintaining a well-rounded life. 
              When I'm not engineering, you can find me:
            </p>
            <ul className="list-disc list-inside text-gray-700">
              <li>Backpacking, fishing, and enjoying the outdoors</li>
              <li>Practicing Jiu Jitsu and staying active through fitness</li>
              <li>3D printing and making Arduino/Raspberry Pi side projects</li>
            </ul>
          </div>
          <div className="w-full md:w-1/3 aspect-w-1 aspect-h-1 rounded-lg overflow-hidden shadow-md order-1 md:order-2">
            <img 
              src="/experience/fish.jpg" 
              alt="Beyond Engineering" 
              className="object-cover w-full h-full"
            />
          </div>
        </motion.div>

        <motion.div 
          {...fadeIn}
        >
          <h2 className="text-xl font-semibold mb-6 text-center text-indigo-600">Let’s see if each picture is really worth a thousand words when there is no context…</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {[1, 2, 3, 4, 5, 6].map((index) => (
              <div key={index} className="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg shadow-md">
                <img 
                  src={`/experience/image${index}.jpg`} 
                  alt={`Life snapshot ${index}`}
                  className="object-cover w-full h-full"
                />
              </div>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default About;