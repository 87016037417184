import React from 'react';
import { Link } from 'react-router-dom';
import { FaLinkedin, FaEnvelope } from 'react-icons/fa';

function Home() {
  return (
    <div className="min-h-screen bg-white">
      <div className="container mx-auto px-4 py-0">
        <div className="flex flex-col md:flex-row items-center md:items-start md:justify-between">
          {/* Left column - Introduction */}
          <div className="md:w-1/2 mb-12 md:mb-0">
            <div className="w-full md:w-[24rem] aspect-[4/3] overflow-hidden mb-8 mx-auto md:mx-24 shadow-lg rounded-lg">
              <img 
                src="/homePage/homePagePic.jpeg" 
                alt="Robert Daszynski" 
                className="w-full h-full object-cover object-center"
              />
            </div>
            <h1 className="text-4xl md:text-5xl font-bold mb-4 text-center md:text-left text-gray-800">
              Robert Daszynski
            </h1>
            <p className="text-xl md:text-xl font-semibold mb-6 text-center md:text-left text-indigo-600">
              Thanks for checking out my portfolio!
            </p>
            <p className="text-lg mb-8 text-center md:text-left text-gray-600">
              👋 Hello, I'm Rob, a senior at the University of Michigan 〽️, studying <strong>Mechanical Engineering</strong> with a minor 
              in <strong>Computer Science</strong>. I am passionate about design and integration engineering, and I am most interested in contributing to
               fast-paced, mission-oriented 
              companies in the aerospace and defense sectors.
            </p>
            <div className="flex justify-center md:justify-start space-x-4 mb-8">
              <a href="https://linkedin.com/in/rmdasz" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-indigo-600 transition-colors">
                <FaLinkedin size={24} />
              </a>
              <a href="mailto:rmdasz@umich.edu" className="text-gray-600 hover:text-indigo-600 transition-colors">
                <FaEnvelope size={24} />
              </a>
            </div>
            <div className="flex justify-center md:justify-start">
              <Link 
                to="/projects" 
                className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-3 px-6 rounded-full transition duration-300 shadow-md"
              >
                View My Projects
              </Link>
            </div>
          </div>

          {/* Right column - About Me */}
          <div className="md:w-1/2 bg-white p-8 rounded-lg shadow-xl">
            <h2 className="text-2xl font-semibold mb-6 text-indigo-600">About Me</h2>
            <p className="mb-4 text-gray-700"> 
            As I reflect on my journey, I've realized that two things consistently drive my passion for engineering: my insatiable curiosity and 
            my love for problem-solving.
            </p>
            <p className="mb-4 text-gray-700">
            My curiosity fuels me to ask questions that others often overlook. Whether it’s the design of a building, 
            the materials used in a product, or the mechanics behind everyday objects, I’m always searching for the "why" behind 
            the systems around me. I find joy in understanding the hidden engineering in the world, and it frustrates me when I can't fully 
            grasp how or why something works. But when I can piece the puzzle together—whether through research, observation, or hands-on 
            experimentation—that’s when I truly feel connected to my work.
            </p>
            <p className="mb-4 text-gray-700">
            In addition, engineering challenges me in ways that no other field can. The journey from confusion to clarity is what I love most about 
            problem-solving. At times, I face complex problems that seem insurmountable. In those moments of uncertainty, I find myself questioning if 
            I’ll ever find the solution. But it's the process of overcoming those obstacles—through failure, trial, and persistence—that makes the eventual 
            success so rewarding. Whether it’s cracking a tough homework problem, assembling a project, or designing a complex fixture, the feeling of
             accomplishment after persevering through difficulties is what keeps me motivated.
            </p>
            <p className="mb-4 text-gray-700">
            I’m constantly driven by the desire to learn and understand the environment around me. I believe that the best solutions come 
            from asking unasked questions and embracing failures, and that’s what I bring to every project I take on. I’m excited to continue growing 
            as an engineer and look forward to embarking on the next chapter of my journey post-graduation next fall. 

            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
