import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const AndonProject = ({ project }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => 
      prevIndex === project.image.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => 
      prevIndex === 0 ? project.image.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="bg-gray-0 min-h-screen">
      <div className="container mx-auto px-4 py-12 max-w-6xl">
        <Link to="/projects" className="text-blue-600 hover:underline mb-8 inline-block text-lg">&larr; Back to Projects</Link>
        
        <h2 className="text-5xl font-bold mb-4 text-center text-gray-800">{project.title}</h2>
        <h3 className="text-2xl font-semibold mb-12 text-center text-gray-600">Manufacturing Efficiency Internship Project</h3>

        <div className="mb-16 relative max-w-md mx-auto">
          <div className="aspect-w-4 aspect-h-3 bg-gray-200 rounded-xl overflow-hidden">
            <img 
              src={project.image[currentImageIndex]} 
              alt={`${project.title} ${currentImageIndex + 1}`} 
              className="w-full h-full object-cover"
            />
          </div>
          <button 
            onClick={prevImage} 
            className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75 transition-all"
          >
            <FaChevronLeft size={24} />
          </button>
          <button 
            onClick={nextImage} 
            className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75 transition-all"
          >
            <FaChevronRight size={24} />
          </button>
          <p className="text-center mt-2 text-gray-600">
            Image {currentImageIndex + 1} of {project.image.length}
          </p>
        </div>

        <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
          <h4 className="text-3xl font-semibold mb-6 text-gray-800">Project Overview</h4>
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">{project.fullDescription}</p>
          <p className="text-gray-700 leading-relaxed text-lg">
            Key Features:
          </p>
          <ul className="list-disc list-inside text-gray-700 space-y-2 text-lg ml-4">
            <li>Improved Wiring and Build</li>
            <li>Real-time production status visualization</li>
            <li>Automated alert system for production issues</li>
            <li>Data analytics dashboard for performance tracking</li>
            
          </ul>
        </section>

        <div className="grid md:grid-cols-2 gap-8 mb-16">
          <section className="bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Implementation Process</h4>
            <ul className="list-disc list-inside text-gray-700 space-y-3 text-lg">
              <li>Improved wiring schematic and installation</li>
              <li>Upgraded build and assembly</li>
              <li>Component design and creation</li>
              <li>Arduino and python script coding</li>
              <li>Data analysis using SQL and PowerBI</li>
              <li>Real-time communication implementation</li>
              <li>User training and documentation</li>
            </ul>
          </section>
          <section className="bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Technologies Used</h4>
            <ul className="list-disc list-inside text-gray-700 space-y-3 text-lg">
              <li>Arduino</li>
              <li>Soldering</li>
              <li>Solidworks</li>
              <li>3D Printing</li>
              <li>C++</li>
              <li>Python</li>
              <li>SQL</li>
              <li>PowerBI</li>
            </ul>
          </section>
        </div>

        <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
          <h4 className="text-3xl font-semibold mb-6 text-gray-800">Skills Demonstrated</h4>
          <div className="flex flex-wrap gap-3">
            {["Lean Manufacturing Principles", "IoT Integration", "Electrical Design", "Data Analysis","3D Modeling and Printing", "Process Documentation"].map((skill, index) => (
              <span key={index} className="bg-blue-100 text-blue-800 text-lg font-medium px-4 py-2 rounded-full">
                {skill}
              </span>
            ))}
          </div>
        </section>

    

        <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
          <h4 className="text-3xl font-semibold mb-6 text-gray-800">Outcomes & Results</h4>
          <p className="text-gray-700 leading-relaxed text-lg mb-4">
          By the end of my summer internship, I successfully got the new ANDON system up and running. This project enabled 
          streamlined communication by giving technicians a greater voice and ownership in the assembly process, significantly
           improving problem detection and resolution speed. I implemented real-time tracking of production metrics, critical
            alerts, downtime, and performance trends through Power BI visualizations. Additionally, I provided clear work 
            instructions, SOPs, and visual aids to facilitate effective use and management of the ANDON system by technicians.
             Ultimately, the new ANDON system contributed to more efficient assembly floor operations and enhanced 
             data-driven decision-making.
          </p>
        </section>

        <section className="bg-white rounded-xl shadow-lg p-8">
          <h4 className="text-3xl font-semibold mb-6 text-gray-800">Reflection</h4>
          <p className="text-gray-700 leading-relaxed text-lg mb-4">
          I thoroughly enjoyed this project, as it allowed me to learn new concepts such as Arduino, constructing 
          wiring schematics, and gave me the opportunity to work hands-on, building it piece by piece and wire by 
          wire. It was rewarding to see the project evolve from a non-functional setup into a fully operational system
           with new lights, custom-designed components, and a visual dashboard displaying key production metrics. This
            project was one of the experiences that sparked my interest in tinkering and exploring unfamiliar fields, 
            pushing me to step out of my comfort zone and realize that barriers to entry are not as intimidating as they 
            seem. 
             
          </p>
        </section>
      </div>
    </div>
  );
};

const ThreeDPrintedProjects = ({ project }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  
// input 3d print images here
  const prints = [
    { name: "Flashight Coupler Adapter", image: "/3d-prints/3d-prints-10.jpg", description: "For our ME 350 class project, we needed to design a coupler that attached to the linkages and held a flashlight securely. This ensured that, during rotation, it could trigger sensors effectively without coming loose." },
    { name: "Label Applicator", image: "/3d-prints/3d-prints-12.jpg", description: "A few friends of mine started a company called Free Bean, and they needed a device that could efficiently and consistently apply their desired labels onto cans of specific dimensions. After spending some time in SolidWorks, this was the result. I am very proud of this design becuase of the internal prints and features that make this design work perfectly. " },
    { name: "Arduino + Bread Board Fixture", image: "/3d-prints/3d-prints-7.jpg", description: "I had an issue with my Arduino and breadboard shifting during use. To address this, I designed a fixture that holds both securely in place for easier operation." },
    { name: "Camera Mount Adapter with GoPro Prongs", image: "/3d-prints/3d-prints-11.jpg", description: "I  bought a Sony ZV-1F camera and wanted to adapt it for use with my GoPro tripod, which only connects via GoPro prongs. To work around this, I designed a fixture that allows my new camera to attach to the GoPro prongs and use the tripod. I also included a fixture for a servo motor to turn the camera on and off remotely via a python script." },
    { name: "Dye Dispenser", image: "/3d-prints/3d-prints-8.jpg", description: "My friends and I often enjoy playing beer dye on game days, but it was common to lose or misplace the dye when we weren’t playing. So, I designed a dispenser to make sure we never have that problem again." },
    { name: "Jumper Cable Organizer ", image: "/3d-prints/3d-prints-9.jpg", description: "Whenever I worked with my Arduino, I always experienced frustration with the loose unorganized cables. This was my solution." }
  ];

  return (
    <div className="bg-gray-0 min-h-screen">
      <div className="container mx-auto px-4 py-12 max-w-6xl">
        <Link to="/projects" className="text-blue-600 hover:underline mb-8 inline-block text-lg">&larr; Back to Projects</Link>
        
        <h2 className="text-5xl font-bold mb-4 text-center text-gray-800">{project.title}</h2>
        <h3 className="text-2xl font-semibold mb-12 text-center text-gray-600">3D Printing Showcase</h3>

        <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
          <h4 className="text-3xl font-semibold mb-6 text-gray-800">Project Overview</h4>
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">{project.fullDescription}</p>
        </section>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {prints.map((print, index) => (
            <div key={index} className="bg-white rounded-xl shadow-lg overflow-hidden">
              <div className="relative aspect-w-16 aspect-h-9">
                <img 
                  src={print.image} 
                  alt={print.name} 
                  className="w-full h-full object-cover"
                />
                
              </div>
              <div className="p-4">
                <h5 className="text-xl font-semibold mb-2 text-gray-800">{print.name}</h5>
                <p className="text-gray-600">{print.description}</p>
              </div>
            </div>
          ))}
        </div>

        <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
          <h4 className="text-3xl font-semibold mb-6 text-gray-800">Technologies Used</h4>
          <div className="flex flex-wrap gap-3">
            {project.technologies.map((tech, index) => (
              <span key={index} className="bg-blue-100 text-blue-800 text-lg font-medium px-4 py-2 rounded-full">
                {tech}
              </span>
            ))}
          </div>
        </section>

        <section className="bg-white rounded-xl shadow-lg p-8">
          <h4 className="text-3xl font-semibold mb-6 text-gray-800">Reflection & Learning</h4>
          <p className="text-gray-700 leading-relaxed text-lg mb-4">
            Diving into 3D printing has been an incredible journey of creativity and problem-solving. Each print presents unique challenges, from optimizing designs for printability to fine-tuning printer settings for the best results. This hobby has not only improved my CAD skills but also strengthened my understanding of manufacturing processes and material properties.
          </p>
          <p className="text-gray-700 leading-relaxed text-lg">
            Moving forward, I'm excited to explore more complex designs, experiment with different materials, and possibly integrate electronics into my prints for interactive projects.
          </p>
        </section>
      </div>

      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={() => setSelectedImage(null)}>
          <div className="max-w-4xl max-h-full p-4">
            <img 
              src={selectedImage.image} 
              alt={selectedImage.name} 
              className="max-w-full max-h-full object-contain"
            />
            <p className="text-white text-center mt-4 text-lg">{selectedImage.name}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export const projectsData = [
  { 
    //ANDON Visual Light Management System Page
    id: 'ANDON-Visual-Light-Management-System', 
    title: 'ANDON Visual Light Management System',
    description: 'A comprehensive visual management system for manufacturing efficiency.',
    fullDescription: `I was assigned this project during my internship at Epirus Inc. The ANDON Visual Light Management System had already been 
    implemented but was not functional, so my goal was to get it up and running and make improvements. For those unfamiliar, the ANDON Visual Light 
    Management System is a key element of the Jidoka methodology within the Toyota Production System (TPS) Lean Manufacturing approach. It is designed 
    to enhance manufacturing efficiency and communication, enabling operators to easily signal when they encounter an issue. The ANDON system reduces 
    downtime and fosters a culture of continuous improvement, where all team members, whether technicians or engineers, actively contribute to maintaining 
    quality and efficiency. My objectives for this project were to implement a fully functional ANDON system, create detailed documentation, and 
    effectively track production metrics and issues.`,

    image: [
      '/andonPics/andon-1.jpg',
      '/andonPics/andon-2.png',
      '/andonPics/andon-3.jpg',
      '/andonPics/andon-4.png',
      '/andonPics/andon-5.png',
      '/andonPics/andon-6.jpg',
      '/andonPics/andon-7.jpg',
      '/andonPics/andon-8.png',
      '/andonPics/andon-9.jpg'
    ],
    render: (project) => <AndonProject project={project} />
  },{ 
    //Product Line Manufacturability Page
    id: 'Product-Line-Manufacturability',
    title: 'Product Line Manufacturability Analysis',
    description: 'Comprehensive analysis of manufacturing processes for a product line.',
    fullDescription: `This project involved implementing a new product on the assembly line at Epirus. It was a baseline project, as the
     product had never been manufactured or time-studied before. Below, you can learn more about how I took a design that had never been produced and 
     prepared it for assembly.`,
    image: [
      '/plinePics/pline-1.png',
      '/plinePics/pline-2.png',
      '/plinePics/pline-3.png',
      '/plinePics/pline-4.png',
      '/plinePics/pline-5.png',
      '/plinePics/pline-6.png',
    ],
    render: (project) => (
      <div className="bg-gray-0 min-h-screen">
        <div className="container mx-auto px-4 py-12 max-w-6xl">
          <Link to="/projects" className="text-blue-600 hover:underline mb-8 inline-block text-lg">&larr; Back to Projects</Link>
          
          <h2 className="text-5xl font-bold mb-4 text-center text-gray-800">{project.title}</h2>
          <h3 className="text-2xl font-semibold mb-12 text-center text-gray-600">Manufacturing Engineering Internship Project</h3>

          <div className="mb-16 flex justify-center space-x-4">
            {project.image.map((img, index) => (
              <div key={index} className="w-1/3 aspect-w-1 aspect-h-1 rounded-xl overflow-hidden shadow-xl bg-gray-0">
                <img 
                  src={img} 
                  alt={`${project.title} ${index + 1}`} 
                  className="w-full h-full object-scale-down transform hover:scale-105 transition-transform duration-300"
                />
              </div>
            ))}
          </div>

          <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Project Overview</h4>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">{project.fullDescription}</p>
          </section>

          <div className="grid md:grid-cols-2 gap-8 mb-16">
            <section className="bg-white rounded-xl shadow-lg p-8">
              <h4 className="text-3xl font-semibold mb-6 text-gray-800">Analysis Process</h4>
              <ul className="list-disc list-inside text-gray-700 space-y-3 text-lg">
                <li>Set up the Prototype Build Station</li>
                <li>Draft DFA Procedure</li>
                <li>Time Study</li>
                <li>Create and Order Tools</li>
                <li>Line Balance</li>
                <li>PFMEA</li>
                <li>Changeover Process</li>
              </ul>
            </section>
            <section className="bg-white rounded-xl shadow-lg p-8">
              <h4 className="text-3xl font-semibold mb-6 text-gray-800">Tools & Techniques Used</h4>
              <ul className="list-disc list-inside text-gray-700 space-y-3 text-lg">
                <li>SolidWorks (for design and tool creation)</li>
                <li>Process simulation in excel (for linebalancing and workflow analysis)</li>
                <li>Time study analysis via excel</li>
                <li>Lean manufacturing principles (e.g., 5S, SMED)</li>
                <li>DFA (Design for Assembly) principles</li>
                <li>PFMEA (Process Failure Mode and Effects Analysis)</li>
              </ul>
            </section>
          </div>

          <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Key Findings</h4>
            <ul className="list-disc list-inside text-gray-700 space-y-3 text-lg">
              <li>Identified bottlenecks in the production process</li>
              <li>Proposed alternative materials for cost reduction</li>
              <li>Suggested automation opportunities</li>
              <li>Developed strategies for reducing waste and improving efficiency</li>
            </ul>
          </section>

          <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Skills Demonstrated</h4>
            <div className="flex flex-wrap gap-3">
              {["Manufacturing Process Analysis", "Cost Optimization", "Quality Control and Risk Management","Design for Assembly", "Lean Manufacturing", "Data Analysis", "Tooling and Fixture Design"].map((skill, index) => (
                <span key={index} className="bg-blue-100 text-blue-800 text-lg font-medium px-4 py-2 rounded-full">
                  {skill}
                </span>
              ))}
            </div>
          </section>

          <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Outcomes & Results</h4>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            Although my internship ended before the newly designed products went into full production, I successfully managed the entire process—from 
            ideation to prototyping, drafting DFA procedures, and changeover processes—setting up the team for success. My team and I assembled and
             disassembled four prototypes from scratch, conducting time studies for both assembly and disassembly for later analysis. My efforts in 
             developing new tools and optimizing procedures reduced the prototype build time by approximately 8 minutes, bringing it down from the 
             original 67 minutes. Additionally, I crafted work instructions that achieved a 91.6% line balance ratio, enhancing overall assembly line 
             efficiency. Through collaboration with the electrical, mechanical, and systems engineering teams, I also conducted a PFMEA analysis to 
             proactively identify and address potential failures. Finally, to ensure a smooth changeover process once the product was on the assembly 
             line, I designed, ordered, and built POU spin changeover racks. These will facilitate a swift and efficient transition between different
              product assemblies, minimizing downtime and disruptions.
            </p>
          </section>

          <section className="bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Reflection</h4>
            <p className="text-gray-700 leading-relaxed text-lg">
            This project strengthened my understanding of how companies move a product from conceptualization to production. Working in a fast-paced 
            startup environment with minimal delays and red tape allowed me to witness a true manufacturing process firsthand. This experience was
             invaluable for several reasons. First, I gained a solid understanding of how to conduct a product line analysis. Second, I learned the 
             importance of designing for manufacturing and ensuring that a part's design considers ease of assembly. Third, this experience enhanced
              my analytical skills and my ability to propose practical solutions to complex manufacturing challenges.
            </p>
          </section>
        </div>
      </div>
    )
  },{ 
    // Auto Plant Watering Page
    id: 'Automatic-Plant-Watering-System', 
    title: 'Automatic Plant Watering System',
    description: 'An IoT-based system for automated plant care.',
    fullDescription: `During my internship at Epirus, I was introduced to the world of mechatronics. My mentor highly recommended that I start working 
    on projects with Arduino and other microcontrollers. After purchasing a kit filled with various components and completing a YouTube course on Arduino
     basics, I felt ready to start making my own projects. That’s when my tendency to forget watering my plants sparked an idea: I wanted to automate the 
     process. This inspiration led to the creation of my automated plant-watering project.`,
    image: [
      '/plantPics/pw-1.jpg',
      '/plantPics/plant-1.jpg',
      '/plantPics/pw-3.jpg'
    ],
    render: (project) => (
      <div className="bg-gray-0 min-h-screen">
        <div className="container mx-auto px-4 py-12 max-w-6xl">
          <Link to="/projects" className="text-blue-600 hover:underline mb-8 inline-block text-lg">&larr; Back to Projects</Link>
          
          <h2 className="text-5xl font-bold mb-4 text-center text-gray-800">{project.title}</h2>
          <h3 className="text-2xl font-semibold mb-12 text-center text-gray-600">Arduino Personal Project</h3>

          <div className="mb-16 flex justify-center space-x-4">
            {project.image.map((img, index) => (
              <div key={index} className="w-1/3 aspect-w-1 aspect-h-1 rounded-xl overflow-hidden shadow-xl bg-gray-0">
                <img 
                  src={img} 
                  alt={`${project.title} ${index + 1}`} 
                  className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                />
              </div>
            ))}
          </div>

          <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Project Overview</h4>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">{project.fullDescription}</p>
          </section>

          <div className="grid md:grid-cols-2 gap-8 mb-16">
            <section className="bg-white rounded-xl shadow-lg p-8">
              <h4 className="text-3xl font-semibold mb-6 text-gray-800">Design Process</h4>
              <ul className="list-disc list-inside text-gray-700 space-y-3 text-lg">
                <li>Research my plant on it's watering needs</li>
                <li>Component selection (Arduino, sensors, pump)</li>
                <li>Circuit design and prototyping</li>
                <li>Programming the Arduino</li>
                <li>Testing and calibration</li>
              </ul>
            </section>
            <section className="bg-white rounded-xl shadow-lg p-8">
              <h4 className="text-3xl font-semibold mb-6 text-gray-800">Technologies Used</h4>
              <ul className="list-disc list-inside text-gray-700 space-y-3 text-lg">
                <li>Arduino</li>
                <li>Soil moisture sensors</li>
                <li>Water pump</li>
                <li>C++ programming</li>
              </ul>
            </section>
          </div>

          <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Key Features</h4>
            <ul className="list-disc list-inside text-gray-700 space-y-3 text-lg">
              <li>Automated soil moisture monitoring</li>
              <li>Customizable watering thresholds</li>
              <li>Real-time data logging</li>
              <li>Low water level alert system</li>
            </ul>
          </section>

          <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Skills Demonstrated</h4>
            <div className="flex flex-wrap gap-3">
              {["Circuit Design", "Arduino Programming", "Sensor Integration", "Problem Solving"].map((skill, index) => (
                <span key={index} className="bg-blue-100 text-blue-800 text-lg font-medium px-4 py-2 rounded-full">
                  {skill}
                </span>
              ))}
            </div>
          </section>

          <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Outcomes & Results</h4>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            By pairing the Arduino with a moisture sensor and water pump, I successfully created a mechanism that waters my plant whenever the moisture
             level drops below a specified threshold. I plan to enhance the setup with additional features, including a stylish 3D-printed fixture that 
             allows the plant to rest securely while housing and concealing components like the Arduino and water pump. I also plan to implement text alerts
              for when the water reservoir is empty, notifying me when the pump can no longer water the plant.
               
            </p>
          </section>

          <section className="bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Reflection</h4>
            <p className="text-gray-700 leading-relaxed text-lg">
            Reflecting on this project, I realized it opened up a world of possibilities for using engineering to solve everyday problems. This was 
            one of the first projects I completed without the guidance of a professor or employer, and it was rewarding to independently determine each 
            next step. Before diving into personal projects, I often felt uncertain about where to begin. I thought the barrier to entry was high and was 
            hesitant to fully commit. Now, personal projects have become a hobby, and to this day, I find time to work on fun and challenging projects that
             push me to think and engineer beyond the classroom.
            </p>
          </section>
        </div>
      </div>
    )
  },{ 
    //Robotic Machine Player Page
    id: 'Robotic-Machine-Player',
    title: 'Robotic Machine Player',
    description: 'A custom-designed autonomous robot for ME 250 course at University of Michigan.',
    fullDescription: `As part of the ME 250 course, I led a team project to design and build an autonomous robotic machine capable of navigating an obstacle course and performing specific tasks. This project showcased our ability to apply mechanical design principles, CAD modeling, and hands-on manufacturing skills.`,
    image: [
    '/rmpPics/rmp-1.jpg',
    '/rmpPics/rmp-2.jpg',
    '/rmpPics/rmp-3.jpg'
    ],
    render: (project) => (
      <div className="bg-gray-0 min-h-screen">
        <div className="container mx-auto px-4 py-12 max-w-6xl">
          <Link to="/projects" className="text-blue-600 hover:underline mb-8 inline-block text-lg">&larr; Back to Projects</Link>
          
          <h2 className="text-5xl font-bold mb-4 text-center text-gray-800">{project.title}</h2>
          <h3 className="text-2xl font-semibold mb-12 text-center text-gray-600">ME 250: Design and Manufacturing I</h3>

          <div className="mb-16 grid grid-cols-1 md:grid-cols-3 gap-6">
            {project.image.map((img, index) => (
              <div key={index} className="aspect-w-4 aspect-h-3 rounded-xl overflow-hidden shadow-xl bg-gray-0">
                <img 
                  src={img} 
                  alt={`${project.title} ${index + 1}`} 
                  className="w-full h-full object-contain transform hover:scale-105 transition-transform duration-300"
                />
              </div>
            ))}
          </div>

          <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Project Overview</h4>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">{project.fullDescription}</p>
            <p className="text-gray-700 leading-relaxed text-lg">
              Our goal was to create a robot that could autonomously navigate an obstacle course, retrieve objects, and avoid obstacles. This challenge required integrating 
              mechanical design, electronics, and programming into a cohesive system.
            </p>
          </section>

          <div className="grid md:grid-cols-2 gap-8 mb-16">
            <section className="bg-white rounded-xl shadow-lg p-8">
              <h4 className="text-3xl font-semibold mb-6 text-gray-800">Design Process</h4>
              <ul className="list-disc list-inside text-gray-700 space-y-3 text-lg">
                <li>Conceptual Design & Prototyping</li>
                <li>CAD Modeling & Simulation</li>
                <li>Manufacturing & Assembly</li>
                <li>Testing & Optimization</li>
              </ul>
            </section>
            <section className="bg-white rounded-xl shadow-lg p-8">
              <h4 className="text-3xl font-semibold mb-6 text-gray-800">Technologies Used</h4>
              <ul className="list-disc list-inside text-gray-700 space-y-3 text-lg">
                <li>SolidWorks</li>
                <li>GD&T</li>
                <li>CNC Machining</li>
                <li>3D Printing</li>
              </ul>
            </section>
          </div>

          <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Key Challenges & Solutions</h4>
            <p className="text-gray-700 leading-relaxed text-lg">
              Throughout this class project, the team and I experienced multiple challenges involving design choices, manufacturing techniques, decision-making, and assembly. 
              Because of these challenges, this class served as a very pivotal moment as it prompted me to grow as an engineer. When faced with difficult design choices, I made
              an effort to seek advice and a new perspective from more experienced engineers. When my team disagreed over certain decisions, we settled it by pushing egos aside and 
              identifying pros and cons of each decision. Manufacturing and assembly challenges often came with efficient time management and supporting all team members. Our team succeeded
               by prioritizing ownership and having trust in each other to ask for help as well as do what is needed to complete their task. 
            </p>
          </section>

          <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Skills Demonstrated</h4>
            <div className="flex flex-wrap gap-3">
              {["Mechanical Systems Design", "CAD/CAM", "Prototyping", "Project Management", "Problem-Solving"].map((skill, index) => (
                <span key={index} className="bg-blue-100 text-blue-800 text-lg font-medium px-4 py-2 rounded-full">
                  {skill}
                </span>
              ))}
            </div>
          </section>

          <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Outcomes & Results</h4>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            Our team’s RMP successfully navigated the obstacle course and completed all tasks as designed. We finished the course in 12 minutes 
            with 95% efficiency. Thanks to our RMP and the many hours of critical thinking and effort we invested in the project, our class placed 
            second in the competition among all classes.
            </p>
            <div className="bg-gray-100 p-6 rounded-lg">
              <h5 className="text-2xl font-semibold mb-4 text-gray-800">Performance Metrics:</h5>
              <ul className="list-disc list-inside text-gray-700 space-y-2 text-lg">
                <li>Course completion rate: 100%</li>
                <li>Navigation time: 12 minutes</li>
                <li>System efficiency: 95%</li>
              </ul>
            </div>
          </section>

          <section className="bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Reflection</h4>
            <p className="text-gray-700 leading-relaxed text-lg">
            As I mentioned previously, this project helped shape me into the engineer I am today. It was the first multi-component project 
            where I worked on designing, manufacturing, and assembling with a team I met at the start of the class. I learned a lot about myself, 
            including my strengths and, most importantly, my weaknesses. I have come to understand that growth often comes from facing challenging 
            problems, and this class taught me to embrace those struggles. Overall, I thoroughly enjoyed this class and project, as it was my first 
            experience taking an idea from concept to a working product.

            </p>
          </section>
        </div>
      </div>
    )
  },{ 
    //Paint Shop Tooling Fixture Page
    id: 'Paint-Shop-Tooling-Fixture',
    title: 'Paint Shop Tooling Fixture',
    description: 'A custom-designed tool fixture.',
    fullDescription: ` Paint Shop Tooling Fixture Page that consisted of ideating and creating a paint shop tooling fixture using machining skills I gained through ME 250 and Solar Car. This project showcased my ability to apply theoretical knowledge to a tangible, real-world application.`,
    image: [
      '/psfPics/psf-1.jpg',
      '/psfPics/psf-2.jpg',
      '/psfPics/psf-3.jpg',
      '/psfPics/psf-4.jpg',
    ],
    render: (project) => (
      <div className="bg-gray-0 min-h-screen">
        <div className="container mx-auto px-4 py-12 max-w-6xl">
          <Link to="/projects" className="text-blue-600 hover:underline mb-8 inline-block text-lg">&larr; Back to Projects</Link>
          
          <h2 className="text-5xl font-bold mb-4 text-center text-gray-800">{project.title}</h2>
          <h3 className="text-2xl font-semibold mb-12 text-center text-gray-600">Internship Project</h3>

          <div className="mb-16 flex justify-center space-x-4">
            {project.image.map((img, index) => (
              <div key={index} className="w-1/4 aspect-w-1 aspect-h-1 rounded-xl overflow-hidden shadow-xl bg-gray-0">
                <img 
                  src={img} 
                  alt={`${project.title} ${index + 1}`} 
                  className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                />
              </div>
            ))}
          </div>

          <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Project Overview</h4>
         
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
              This project came to fruition while I was interning at Woodward Aerospace summer of my sophomore year. As a continous improvement engineering intern, 
              I conducted daily walks around the factory floor where I would frequently engage with floor and assembly technicians. After some small talk, I would 
              pose the question: 'What are you frustrated with in or around your work station?' This allowed me to spot opportunity for improving processes on the 
              factory floor. 
          </p>
            <p className="text-gray-700 leading-relaxed text-lg">
              I found this project when talking to Daniel, one of the paint shop employees. He had an issue with painting a production part that had a nonuniform shape and 
              an unbalanced center of mass making it extremely difficult to do so. After hearing his complaint, I saw an opportunity for improving his process.  
             </p>
          </section>

          <div className="grid md:grid-cols-2 gap-8 mb-16">
            <section className="bg-white rounded-xl shadow-lg p-8">
              <h4 className="text-3xl font-semibold mb-6 text-gray-800">Design Process</h4>
              <ul className="list-disc list-inside text-gray-700 space-y-3 text-lg">
                <li>Ideation with paint shop operators</li>
                <li>Sketched prototypes and consulted sr. engineers</li>
                <li>CAD modeling in CreoCAD</li>
                <li>Technical drawings with GD&T</li>
                <li>Machined, 3D printed, and procured</li>
                <li>Documentated steps and make SOP</li>
                <li>Final assembly and finishing</li>
              </ul>
            </section>
            <section className="bg-white rounded-xl shadow-lg p-8">
              <h4 className="text-3xl font-semibold mb-6 text-gray-800">Technologies Used</h4>
              <ul className="list-disc list-inside text-gray-700 space-y-3 text-lg">
                <li>CreoCAD</li>
                <li>Machining</li>
                <li>3D printing</li>
          
              </ul>
            </section>
          </div>

          <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Key Features</h4>
            <ul className="list-disc list-inside text-gray-700 space-y-3 text-lg">
              <li>Ideated a design that fits the paint shop technicians' requirements</li>
              <li>Designed a three component solution in CreoCAD</li>
              <li>Applied GD&T on the base plate drawing for shop approval</li>
              <li>Different material choice for each component</li>
              <li>Implemented DFM principles for reduced costs during mass production</li>
              <li>Successfully created a burr-free problem-solving product</li>

            </ul>
          </section>

          <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Skills Demonstrated</h4>
            <div className="flex flex-wrap gap-3">
              {["CAD modeling", "GD&T application","3D Printing" ,"Machine shop operations", "Documentation and SOPs","Project planning and execution"].map((skill, index) => (
                <span key={index} className="bg-blue-100 text-blue-800 text-lg font-medium px-4 py-2 rounded-full">
                  {skill}
                </span>
              ))}
            </div>
          </section>

          <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Outcomes & Results</h4>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
              Once the product manufacturing was finished and implemented into the paint shop processes, I took notes of the resulting outcomes. First off, the product 
              was very well received by the paint shop technicians, with Daniel and his team reporting reduced frustration and increased ease in their workflow. The
              fixture also reduced painting time by almost 25% and minimized the risks associated with handling the unbalanced parts which contributed to a safer work environment.
              Finally, I created a standard operating procedure for future production ensuring consistency and repeatable use of this fixture.
            </p>
          </section>

          <section className="bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Reflection</h4>
            <p className="text-gray-700 leading-relaxed text-lg">
              This project reinforced my understanding of the entire product development process, from initial concept to final production. It highlighted the importance of precise measurements, the value of GD&T in communicating design intent, and the practical challenges of translating CAD models into physical objects. The experience gained from this project has been invaluable in subsequent engineering endeavors.
            </p>
          </section>
        </div>
      </div>
    )
  },{ 
    //Pencil Holder Page
    id: 'Pencil-Holder',
    title: 'Pencil Holder',
    description: 'A custom-designed and fabricated pencil holder showcasing machining skills.',
    fullDescription: `During my sophomore year, I joined the prestigious University of Michigan Solar Car Team. After I joined and completed the new member
    trainings I had an opportunity to complete additional training. These trainings would allow me to gain access to the Wilson Student Team Center - a 
    fabrication shop with equipment that is not readily availble to most of the student body. This project was one of the projects I was tasked with. 
    Although very similar to my fabricated rook chess piece it differs in the fabrication techniques used.`,
    image: [
      '/pencilPics/pencil-1.jpg',
      '/pencilPics/pencil-2a.jpg',
      '/pencilPics/pencil-2b.jpg',
      '/pencilPics/pencil-3.jpg'
    ],
    render: (project) => (
      <div className="bg-gray-0 min-h-screen">
        <div className="container mx-auto px-4 py-12 max-w-6xl">
          <Link to="/projects" className="text-blue-600 hover:underline mb-8 inline-block text-lg">&larr; Back to Projects</Link>
          
          <h2 className="text-5xl font-bold mb-4 text-center text-gray-800">{project.title}</h2>
          <h3 className="text-2xl font-semibold mb-12 text-center text-gray-600">Personal Training Project</h3>

          <div className="mb-16 flex justify-center space-x-4">
            {project.image.map((img, index) => (
              <div key={index} className="w-1/4 aspect-w-1 aspect-h-1 rounded-xl overflow-hidden shadow-xl bg-gray-0">
                <img 
                  src={img} 
                  alt={`${project.title} ${index + 1}`} 
                  className="w-full h-full object-scale-down transform hover:scale-105 transition-transform duration-300"
                />
              </div>
            ))}
          </div>

          <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Project Overview</h4>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">{project.fullDescription}</p>
          </section>

          <div className="grid md:grid-cols-2 gap-8 mb-16">
            <section className="bg-white rounded-xl shadow-lg p-8">
              <h4 className="text-3xl font-semibold mb-6 text-gray-800">Design Process</h4>
              <ul className="list-disc list-inside text-gray-700 space-y-3 text-lg">
              <li>Create manufacturing instructions using the technical drawing</li>
              <li>Fabricated using stamping press(bend), hole puncher, drill press, and tap - correct</li>
              <li>Deburring and Final Assembly</li>
              </ul>
            </section>
            <section className="bg-white rounded-xl shadow-lg p-8">
              <h4 className="text-3xl font-semibold mb-6 text-gray-800">Technologies Used</h4>
              <ul className="list-disc list-inside text-gray-700 space-y-3 text-lg">
                <li>GD&T</li>
                <li>Machining: Horizontal bandsaw, drilling, tapping, sheet metal shear, punch, brake, filing & deburring</li>
                
              </ul>
            </section>
          </div>

          <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Key Features</h4>
            <ul className="list-disc list-inside text-gray-700 space-y-3 text-lg">
            <li>Applied GD&T on all drawings</li>
            <li>Created a manufacturing plan using the technical drawing</li>
            <li>Used various manufacturing techniques including the horizontal bandsaw, drilling, tapping, sheet metal shear, punch, brake, filing & deburring</li>
            <li>Successfully fabricated a burr-free functional product</li>
            </ul>
          </section>

          <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Skills Demonstrated</h4>
            <div className="flex flex-wrap gap-3">
              {[ "Writing manufacturing instructions","GD&T application", "Machine shop operations", "Project planning and execution"].map((skill, index) => (
                <span key={index} className="bg-blue-100 text-blue-800 text-lg font-medium px-4 py-2 rounded-full">
                  {skill}
                </span>
              ))}
            </div>
          </section>

          <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Outcomes & Results</h4>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
              After devoting some time and effort, I was able to fabricate a finished and functional pencil holder that met all design specifications. 
              Since its creation, it has been serving its duty on my desk. 
            </p>
          </section>

          <section className="bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Reflection</h4>
            <p className="text-gray-700 leading-relaxed text-lg">
              This project was a step up in difficulty from the fabricated rook chess piece. At least for a newcomer to fabrication. I made several mistakes during this
              training and none of these mistakes were easily fixable. If I recall correctly, I had to restart three different times before I learned the importance of 
              GD&T in manufacturing. This project serves as my reminder to 'measure twice, cut once'. Overall, this project was
              enjoyable to create due to the amount of new manufacturing techniques and machines I had to use. It exposed me to the abundant world of manufacturing capability
              and sparked my interst further. 

            </p>
          </section>
        </div>
      </div>
    )
  },{ 
    //Fabricated Rook Chess Piece Page
    id: 'Fabricated-Rook-Chess-Piece',
    title: 'Fabricated Rook Chess Piece',
    description: 'A custom-designed and fabricated rook chess piece.',
    fullDescription: `This project served as a test of my fabrication readiness for my Design and Engineering I class. The premise was that before I started
                      fabricating the parts for my class project, I needed to prove to my instructors that I have the necessary skills to complete basic
                      machining skills - specifically the mill and lathe.`,
    image: [
      '/rookPics/rook-1.jpg',
      '/rookPics/rook-2.jpg',
      '/rookPics/rook-3.jpg'
    ],
    render: (project) => (
      <div className="bg-gray-0 min-h-screen">
        <div className="container mx-auto px-4 py-12 max-w-6xl">
          <Link to="/projects" className="text-blue-600 hover:underline mb-8 inline-block text-lg">&larr; Back to Projects</Link>
          
          <h2 className="text-5xl font-bold mb-4 text-center text-gray-800">{project.title}</h2>
          <h3 className="text-2xl font-semibold mb-12 text-center text-gray-600">Personal Project</h3>

          <div className="mb-16 flex justify-center space-x-4">
            {project.image.map((img, index) => (
              <div key={index} className="w-1/3 aspect-w-1 aspect-h-1 rounded-xl overflow-hidden shadow-xl bg-gray-0">
                <img 
                  src={img} 
                  alt={`${project.title} ${index + 1}`} 
                  className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                />
              </div>
            ))}
          </div>

          <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Project Overview</h4>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">{project.fullDescription}</p>
          </section>

          <div className="grid md:grid-cols-2 gap-8 mb-16">
            <section className="bg-white rounded-xl shadow-lg p-8">
              <h4 className="text-3xl font-semibold mb-6 text-gray-800">Engineering Process</h4>
              <ul className="list-disc list-inside text-gray-700 space-y-3 text-lg">
                <li>Create manufacturing instructions using the technical drawing</li>
                <li>Fabricate using band saw, mill, and lathe</li>
                <li>Deburring and Final Assembly</li>
              </ul>
            </section>
            <section className="bg-white rounded-xl shadow-lg p-8">
              <h4 className="text-3xl font-semibold mb-6 text-gray-800">Technologies Used</h4>
              <ul className="list-disc list-inside text-gray-700 space-y-3 text-lg">
                <li>SolidWorks</li>
                <li>GD&T</li>
                <li>Machining</li>
              </ul>
            </section>
          </div>

          <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Key Features</h4>
            <ul className="list-disc list-inside text-gray-700 space-y-3 text-lg">
              <li>Applied GD&T on all drawings</li>
              <li>Created a manufacturing plan using the technical drawing</li>
              <li>Used various manufacturing techniques including band saw, mill, and lathe</li>
              <li>Successfully fabricated a burr-free functional product</li>
            </ul>
          </section>

          <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Skills Demonstrated</h4>
            <div className="flex flex-wrap gap-3">
              {["Manufacturing instructions", "GD&T application", "Machine shop operations", "Project planning and execution"].map((skill, index) => (
                <span key={index} className="bg-blue-100 text-blue-800 text-lg font-medium px-4 py-2 rounded-full">
                  {skill}
                </span>
              ))}
            </div>
          </section>

          <section className="mb-16 bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Outcomes & Results</h4>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
              With the use of correct GD&T and concise manufacturing instructions I was able to fabricate a finished rook chess piece.
               The final assembly consisted of two indiviual pieces - the base and the crown. They were connected together by a single screw that entered through the crown's
              through-hole and into the threaded hole of the base peice. Once fully assembled it resulted in a fully functional and aesthetically pleasing rook chess piece 
              that met all design specifications. It now resides on my desk as a paper weight.
            </p>
          </section>

          <section className="bg-white rounded-xl shadow-lg p-8">
            <h4 className="text-3xl font-semibold mb-6 text-gray-800">Reflection</h4>
            <p className="text-gray-700 leading-relaxed text-lg">
              When I started this project, I was a young sophomore student who had never machined anything complex. Looking back on this project, I realized
              that this was my first experience using a mill and lathe. With that being said, I am glad I did becuase it taught me many important lessons.
              I learned the  importance of precise measurements, the value of GD&T in communicating design intent, and the practical challenges of translating 
              CAD models into physical objects. 
            </p>
          </section>
        </div>
      </div>
    )
  },{ 
    //My 3D Printed Projects Page
    id: 'My-3D-Printed-Projects', 
    title: 'My 3D Printed Projects', 
    description: 'A collection of my 3D prints showcasing functional and creative design.',
    fullDescription: "This past summer, I caved and bought the BambuLab A1 Mini 3D printer. Now I'm officially addicted to turning plastic into random gadgets. Here are the prints I'm most proud of — enjoy!",
    technologies: ['SolidWorks','Siemens NX', 'CreoCAD', 'Fusion 360', 'Onshape'],
    render: (project) => <ThreeDPrintedProjects project={project} />
  },
];

function ProjectDetail() {
  const { id } = useParams();
  const location = useLocation();
  const project = projectsData.find(p => p.id === id);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (!project) {
    return <div>Project not found</div>;
  }

  // If the project has a custom render function, use it
  if (project.render) {
    return project.render(project);
  }

  // Otherwise, use the default rendering logic
  return (
    <div className="container mx-auto px-4 py-8">
      <Link to="/projects" className="text-blue-500 hover:underline mb-4 inline-block">&larr; Back to Projects</Link>
      <h1 className="text-3xl font-bold mb-4">{project.title}</h1>
      
      {Array.isArray(project.image) ? (
        <div className="mb-8 flex justify-center">
          <div className="relative w-full max-w-xl">
            <img 
              src={project.image[currentImageIndex]} 
              alt={`${project.title} featured`} 
              className="w-full h-auto object-contain rounded-lg shadow-lg"
            />
            <button 
              onClick={() => setCurrentImageIndex(prev => prev === 0 ? project.image.length - 1 : prev - 1)} 
              className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75 transition-all"
            >
              <FaChevronLeft />
            </button>
            <button 
              onClick={() => setCurrentImageIndex(prev => (prev + 1) % project.image.length)} 
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75 transition-all"
            >
              <FaChevronRight />
            </button>
          </div>
        </div>
      ) : (
        <img src={project.image} alt={project.title} className="w-full max-w-2xl mb-6 rounded-lg shadow-md" />
      )}

      <p className="text-gray-700 mb-6">{project.fullDescription}</p>
      
      {project.technologies && (
        <>
          <h2 className="text-2xl font-semibold mb-3">Technologies Used</h2>
          <div className="flex flex-wrap mb-6">
            {project.technologies.map((tech, index) => (
              <span key={index} className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 mb-2 px-2.5 py-0.5 rounded">{tech}</span>
            ))}
          </div>
        </>
      )}
      
      {project.process && (
        <>
          <h2 className="text-2xl font-semibold mb-3">Development Process</h2>
          <ol className="list-decimal list-inside mb-6">
            {project.process.map((step, index) => (
              <li key={index} className="text-gray-700 mb-2">{step}</li>
            ))}
          </ol>
        </>
      )}
      
      {project.skills && (
        <>
          <h2 className="text-2xl font-semibold mb-3">Skills Demonstrated</h2>
          <ul className="list-disc list-inside mb-6">
            {project.skills.map((skill, index) => (
              <li key={index} className="text-gray-700 mb-2">{skill}</li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}

export default ProjectDetail;